html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
.bg-darkbg {
  --tw-bg-opacity: 1;
  background-color: rgb(28 30 38/var(--tw-bg-opacity));
}
.font-display {
  font-family: JetBrains Mono,Segoe UI,Helvetica Neue,Arial,sans-serif;
}
.text-orange {
  --tw-text-opacity: 1;
  color: rgb(240 148 131/var(--tw-text-opacity));
}
.text-regular{
  --tw-text-opacity: 1;
  color: rgb(204 204 204/var(--tw-text-opacity));
}
.text-purple {
  --tw-text-opacity: 1;
  color: rgb(184 119 219/var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(233 86 120/var(--tw-text-opacity));
}
.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(250 183 149/var(--tw-text-opacity));
}
.text-dark {
  --tw-text-opacity: 1;
  color: rgb(132 144 165/var(--tw-text-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0/var(--tw-bg-opacity));
}
.leading-loose {
  line-height: 2;
}
.shadow, .shadow-lg {
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px -1px var(--tw-shadow-color);
}
.md\:text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.lg\:mb-0 {
  margin-bottom: 0;
}
.p-4 {
  padding: 1rem;
}
.border-teal {
  --tw-border-opacity: 1;
  border-color: rgb(37 176 188/var(--tw-border-opacity));
}
.border-2 {
  border-width: 2px;
}
